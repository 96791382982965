import { concat } from "lodash"

export const filteredoobFunc = (data,pair) => {
    // console.log(Object.values(this.xoobs))
    if(data !== null){
     var d = data.datay
     var len = data.datay.length
     var i = 0, ii = len
     var a = []
     var t = pair
    for(i; i < ii; i++) if(
      i < 28
      ) {
       if(d[i][t] != null){
        a.push(d[i])
     }
    }
   }
    return a
  }
export const extractBucketPrices = (data) => {
    const buys = data[0].EUR_USD[0].B
    const sells = data[0].EUR_USD[1].S
    const rbuys = buys.map(obj => ({
        dir: obj.dir,
        bucketsPrice: obj.bucketsPrice
    }))
    const rsells = sells.map(obj => ({
        dir: obj.dir,
        bucketsPrice: obj.bucketsPrice
    }))
    return concat(rbuys,rsells)
}
export const yAno = (data) => {
    return {y: data,
        strokeDashArray: 0,
        borderColor: '#00E396',
        label: {
          borderColor: '#00E396',
          style: {
            color: '#fff',
            background: '#00E396',
          },
          text: data,
        },
      }
}